import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { Layout, PostCard, PostCardSanity, Pagination } from '../components/common'
import { MetaData } from '../components/common/meta'
import { BlockSerializer } from "../utils/BlockSerializer"
import Image from "gatsby-plugin-sanity-image"

/**
* Author page (/author/:slug)
*
* Loads all posts for the requested author incl. pagination.
*
*/
const Author = ({ data, location, pageContext }) => {
    const author = data.sanityAuthor
    const posts = data.allSanityPost.edges
    const twitterUrl = author.twitter
    const facebookUrl = author.facebook
    const youtubeUrl = author.youtube

    return (
        <>
            <MetaData
                data={data}
                location={location}
                type="profile"
                language={pageContext.lang}
            />
            <Layout language={pageContext.lang}>
                <div className="container">
                    <header className="author-header">
                        <div className="author-header-content">
                            <h1>{author.name}</h1>
                                <BlockSerializer>{author._rawBio}</BlockSerializer>
                            <div className="author-header-meta">
                                {author.website && <a className="author-header-item" href={author.website} target="_blank" rel="noopener noreferrer">Կայք</a>}
                                {twitterUrl && <a className="author-header-item" href={twitterUrl} target="_blank" rel="noopener noreferrer">{author.twitter_anchor}</a>}
                                {youtubeUrl && <a className="author-header-item" href={youtubeUrl} target="_blank" rel="noopener noreferrer">{author.youtube_anchor}</a>}
                                {facebookUrl && <a className="author-header-item" href={facebookUrl} target="_blank" rel="noopener noreferrer">{author.facebook_anchor}</a>}
                            </div>
                        </div>
                        <div className="author-header-image">
                            {author.image && <Image {...author.image} alt={author.name} />}
                        </div>
                    </header>
                    <section className="post-feed">
                        {posts.map(({ node }) => (
                            // The tag below includes the markup for each post - components/common/PostCard.js
                            <PostCardSanity key={node.id} post={node} />
                        ))}
                    </section>
                    <Pagination pageContext={pageContext} />
                </div>
            </Layout>
        </>
    )
}

Author.propTypes = {
    data: PropTypes.shape({
        ghostAuthor: PropTypes.shape({
            name: PropTypes.string.isRequired,
            cover_image: PropTypes.string,
            profile_image: PropTypes.string,
            website: PropTypes.string,
            bio: PropTypes.string,
            location: PropTypes.string,
            facebook: PropTypes.string,
            twitter: PropTypes.string,
        }),
        allGhostPost: PropTypes.object,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
}

export default Author

export const pageQuery = graphql`
    query GhostAuthorQuery($slug: String!, $limit: Int!, $skip: Int!) {
        sanityAuthor(slug: {current: {eq: $slug}}) {
            ...SanityAuthorFields
        }
        allSanityPost(
            sort: { order: DESC, fields: [publishedAt] },
            filter: {author: {slug: {current: {eq: $slug}}}},
            limit: $limit,
            skip: $skip
        ) {
            edges {
                node {
                ...SanityPostFields
                }
            }
        }
    }
`
